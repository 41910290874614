import Vue from 'vue'
// import './plugins/element.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 导入全局样式表
import './assets/css/global.css'


import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css' //引用 element-ui 样式

import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import VCharts from 'v-charts'

import '@/styles/index.scss' // global css

import '@/icons' // icon
// import '@/permission' // permission control

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import App from './App.vue'
import router from './router'
//import './utils/pxtorem'//自适应布局,去除这一行即可取消自适应
Vue.use(VXETable)

Vue.use(ElementUI, {
  locale
})
Vue.use(VCharts)

// 开发

import store from './store'

import echarts from 'echarts'

import axios from 'axios'
//配置请求根路径
// ps这个项目的地址也是8080，后端也是，这可以吗？还有这个地址不一定正确，因为我还没有实现后端项目的运行

axios.defaults.baseURL = 'https://xiangqian.club:8001/'
// axios.defaults.baseURL = 'http://localhost:8001/'

// axios.defaults.baseURL = '/api'
// 特定api要有服务器分发的token才可使用
axios.interceptors.request.use(function (config) {
  let token = sessionStorage.getItem('Authorization');
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.prototype.$http = axios

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
